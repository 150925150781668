@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
* {
  box-sizing: border-box;
}




body {
  margin: 0;
  padding: 0;
  font-family:  'Arimo', sans-serif;
}

h1, h2, h3, h4 {
  font-family: 'Rubik', sans-serif;
  color: #111;
}

a{
  text-decoration: none;
}

a:visited{
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
  -webkit-text-decoration-color: white;
          text-decoration-color: white;
}

.text-highlight{
  color: #800
}

.container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
  min-height:85vh;
}

@media (min-width: 769px) {
  .container {
    margin: 2.5rem auto;
    min-height:75vh;

  }
}


/*Footer*/

.footer {
  display:'flex';
  padding: 10px 30px;
  background: #a73737;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #7a2828, #a73737); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

/*  flexDirection:'column', minHeight:'15vh', justifyContent: 'space-around', alignItems:'center'*/
}

.footer-takshashila {

}

.footer-logo {
  height: 50px;
}


.footer-takshashila:hover > .footer-logo {
  opacity: 0.7;
}


.footer-text {
  color: white;
  margin: 0;
  font-family: 'Lato';
  font-weight: 300;
  font-size: 20px;
}

.footer-takshashila:hover > .footer-text {
  opacity: 0.5;
}

.footer-text:hover {
  opacity: 0.5;
}

/*
.footer-text:hover{
    opacity: 0.7;
}

*/


/* Result Component */
.result {
  padding: 1.5rem 2.5rem;
}

.share-icon {
  padding: 5
}
/* Welcome Component*/
.welcome-outer {
  position: relative;
  margin: 0 auto;
  background: #490c29;
  background: #a73737;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #7a2828, #a73737); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  min-height: 85vh

}



.welcome-inner {
  position: relative;
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
/*  vertical-align: 'center'*/

}

.welcome-heading {
  margin:0;
  font-family: Rubik;
  font-size: 35px;
  color: #0009;
}

.welcome-subheading {
  margin-top: 50;
  font-family: 'Lato';
  font-weight: 300;
  font-size: 20px;
  color: #fff;
}

.welcome-button {
  background-color: #fff;
  border: none;
  color: #000000aa;
  font-family: Arimo;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 30px;
  width: 180px;
}

.welcome-button:hover{
  background-color: #fffc;
}


.labelled-mandala {
  max-height: 50vh;
  margin-left: auto;
  margin-right: auto;
  display: block;

}

.contest-outer {
  position: relative;
  margin: 0 auto;
  min-height: 85vh

}



.contest-inner {
  position: relative;
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
/*  vertical-align: 'center'*/

}


/* Quiz Component */
.answerOptions {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* QuestionCount Component */
.questionCount {
  padding: 1.5rem 2.5rem 0;
  font-size: 14px;
}

/* Question Component */
.question {
  margin: 0;
  padding: 0.5rem 2.5rem 1.5rem 2.5rem;

}

/* AnswerOption Component */
.answerOption {
  border-top: 0.5px solid #efefef;

}

.answerOption:hover {
  background: #E6F9E8;
}

.radioCustomButton {
  position: absolute;
  width: auto;
  opacity: 0;
}

.radioCustomButton,
.radioCustomLabel {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.radioCustomLabel {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 1.5rem 2.5rem 1.5rem 5rem;
  font-size: 16px;
  line-height: 1.5;
}

.radioCustomButton ~ .radioCustomLabel:before {
  position: absolute;
  top: 20px;
  left: 38px;
  width: 28px;
  height: 28px;
  content: '';
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  transition: all 0.3s;
}

.radioCustomButton:checked ~ .radioCustomLabel:before {
  content: '';
  /*background: #8bc53f url(./svg/icon-check.svg) no-repeat;*/
  background: #C7EEBC url(/static/media/icon-check.4cffa739.svg) no-repeat;
  background-size: 27px;
  border-color: #C7EEBC;
/*  border-color: #eb9130;
*/}

.next-button {
  background-color: #FDB900;
  border: none;
  color: #fff;
  font-family: Arimo;
  padding: 15px 32px;
  margin:25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px 25px 25px 10px;
  width: 120px;
}

.next-button:hover{
  background-color: #FDB900cc;
}


.next-button:disabled{
  background-color: #333;
}

.contest {
  display:'flex';
  padding: 10px 10px;
  color: #fff;
  background: #FDB900;  /* fallback for old browsers */
/*  flexDirection:'column', minHeight:'15vh', justifyContent: 'space-around', alignItems:'center'*/
}




/* Animation */
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.App-logo {
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.T-logo {
  height: 50px;
}

.App-header {
/*s*/
  background: #ccc;  /* fallback for old browsers */
  background-image: url(/static/media/background.5add2f47.png);  
  padding: 10px 30px;
  background: #a73737;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #7a2828, #a73737); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */	
  display:'flex'; 
  /*color: black;
  text-align: center;*/
}

.App-intro {
  font-size: large;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



