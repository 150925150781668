@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.App-logo {
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.T-logo {
  height: 50px;
}

.App-header {
/*s*/
  background: #ccc;  /* fallback for old browsers */
  background-image: url("img/background.png");  
  padding: 10px 30px;
  background: #a73737;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #7a2828, #a73737);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #7a2828, #a73737); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */	
  display:'flex'; 
  /*color: black;
  text-align: center;*/
}

.App-intro {
  font-size: large;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


